@import 'variables';
@import 'icons/css/fontello.css';
@import 'icons/css/animation.css';

$mobile-breakpoint: 991px;

$background: #fff;
$background-light: #f1f1f1;
$text-color: #000;
$text-color-light: #9e9e9e;

$border-color: #a6a6a6;
$border-color-light: #868686;
$border: 2px solid $border-color;

.cs-overlay {
  display: none;
}

body.cs-open {
  height: 100vh;
  overflow-y: hidden;
  padding-right: 15px;

  .cs-overlay {
    display: block;
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 10000; /* Sit on top */
    left: 0;
    top: 0;
    background-color: $background;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.1s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

    overflow: hidden;
    color: $text-color;

    &.cs-loading {
      .cs-column-content {
        opacity: 0.7;
      }
    }


    .cs-content {
      // padding: 15px;

      .cs-header {
        display: flex;
        flex-direction: column;

        .cs-search {
          display: flex;
          flex-direction: row;
        }

        .cs-hints {
          padding: 10px 0;
          .cs-btn {
            margin: 0 5px 0 0;
          }
        }
      }

      .cs-columns-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        height: 90vh;

        .cs-column-side {
          overflow: auto;
          width: 20%;
          min-width: 400px;
          z-index: 10001;
          background: $background;

          border-right: 2px solid $background-light;

          // left: 100%; // transition
          transition: left 200ms ease;

          .cs-side-header {
            position: sticky;
            top: 5px;
            background: $background-light;
            padding: 5px;
          }

          .cs-side-footer {
            position: sticky;
            bottom: 5px;
            background: $background-light;
            padding: 5px;
          }

          @media(max-width: 800px) {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 100%;
           // background: #000;
          }

          &.open {
            display: block;
            position: fixed;
            //background: #000;
            top: 0;
            right: 0;
            bottom: 0;
            left: 50px;
          }

          // Filters c
          .cs-filter-group {

            padding: 10px;
            border-bottom: 2px solid $background-light;

            .cs-filter-group-header {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: space-between;

              .header-name {
                flex-basis: 80%;
                font-weight: 600;
                margin-bottom: 10px;
              }
              .header-nb {
                color: $text-color-light;
              }

              &:hover {
                cursor: pointer;
              }
              i {
                font-size: 10px;
              }
            }
            .cs-params {
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: flex-start;

              .cs-filter {
                text-align: left;
              }
              .param {
                text-align: left;
                display: block;
              }
            }
          }
        }

        .cs-column-content {
          overflow: auto;
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;

          .cs-sort-bar {
            width: 100%;
            padding: 5px;
            background: $background-light;
          }

          .miniature {
            border-radius: 5px;
            &:hover {
              background: #e8e8e8;
            }
            .miniature-image {
              width: 100%;
              height: auto;
            }
            .images-container {
              position: relative;
              display: block;
              .badges-container {
                position: absolute;
                top: 15px;
                right: 0;
                .badge-new {
                  background: #000;
                  color: #fff;
                  padding: 0 10px;
                }
              }
            }
            .name-container {
              display: inline-block;
              margin: 5px 15px;
              h3 {
                margin: 0;
              }
            }
            .prices-container {
              display: inline-block;
              margin: 5px 15px;
              .price-reduced {
                text-decoration: line-through;
              }
            }
            .actions-container {
              display: inline-block;
              margin: 5px 15px;
            }

            &-grid {
              max-width: 350px;
              padding: 10px;

              width: 33%;
              @media(max-width: 776px) {
                width: 50%;
              }
              @media(max-width: 425px) {
                width: 100%;
              }
            }

            &-list {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: space-between;

              padding: 10px;
              width: 100%;

              .actions-container {
                .cs-text {
                  @media(max-width: 776px) {
                    display: none;
                  }
                }
              }
              .images-container {
                .miniature-image {
                  width: 125px;
                  @media(max-width: 776px) {
                    width: 60px;
                  }
                }
              }

            }
          }
        }
      }



      // global
      select {
        padding: 6px;
        display: inline-block;
      }
      input, select {
        border-radius: 0;
        border: 0;
        outline: none;
      }

      .cs-dim {
        opacity: 0.5;
      }




      // tmp
      > * {
        padding: 5px;
      }
      .cs-columns-container > * {
        padding: 5px;

        .miniature {
        }
      }
       .cs-sort-bar {

      }
    }
  }
  label {
    // color: #fff;
  }


}




// scroll
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

// buttons
.cs-btn {

  padding: 10px;
  color: $text-color-light;
  border: $border;

  &:hover {
    cursor: pointer;
    border-color: $border-color-light;
  }

  &.sm {
    padding: 2px 5px;
    font-size: 10px;
  }
}

//.cs-container {
//  display: block;
//  height: 100%;
//  width: 100%;
//  position: fixed; /* Stay in place */
//  left: 0;
//  top: 0;
//  overflow-x: hidden; /* Disable horizontal scroll */
//  transition: 0.1s;
//  z-index: 1000000;
//
//  background: $background;
//
//  .cs-header {
//    position: fixed;
//    top: 0;
//    width: 100%;
//    padding: 15px;
//    background: #f1f1f1;
//    z-index: 10;
//
//    +.cs-listing {
//    }
//
//
//    @media (min-width: $mobile-breakpoint) {
//      position: initial;
//    }
//  }
//
//  .cs-listing {
//    padding: 15px;
//
//    display: flex;
//    flex-direction: row;
//    flex-wrap: nowrap;
//
//    height: 90vh;
//
//    .cs-filters {
//      transition:left 200ms ease;
//      z-index: 100;
//      background: #f1f1f1;
//      padding: 15px;
//
//      // mobile first
//      position: fixed;
//      top: 0;
//      left: 100%;
//      right: 0;
//      bottom: 0;
//    }
//
//    .pr-list {
//
//    }
//
//    @media (min-width: $mobile-breakpoint) {
//      display: flex;
//      flex-direction: row;
//      flex-wrap: nowrap;
//      justify-content: space-between;
//
//      .cs-filters {
//        min-width: 300px;
//        position: initial;
//      }
//    }
//  }
//}
//
//////
//.cs-container.cs-filters-aside {
//  overflow: hidden;
//  .cs-filters {
//    left: 50px;
//
//    &__content {
//      overflow-y: scroll;
//      height: 90%;
//    }
//  }
//}
//
//////
//body.cs-active {
//  overflow: hidden;
//  margin: 0;
//  padding: 0;
//  width: 100%;
//  height: 100%;
//  position: relative;
//}