


































































































































































































































































































































.grid-2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .miniature {
    width: calc(50% - 5px);
    overflow: hidden;
    margin-bottom: 30px;
  }
}

.grid-3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .miniature {
    width: calc(33% - 5px);
    overflow: hidden;
    margin-bottom: 30px;
  }
}

.grid-4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .miniature {
    width: calc(25% - 5px);
    overflow: hidden;
    margin-bottom: 30px;
  }
}

